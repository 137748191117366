// eslint-disable-next-line import/prefer-default-export
/* eslint-disable no-mixed-operators */
export const calculateFormula = (
  clusterCorrection, cluster, randomCorrection, random, size, sampling
) => {
  const value = sampling.samplingMethod === 'Cluster'
    ? size.correction ? clusterCorrection(size, sampling) : cluster(size, sampling)
    : size.correction ? randomCorrection(size, sampling) : random(size, sampling);

  return (Number.isNaN(value) || !Number.isFinite(value)) ? 0 : value;
};

export const calculateRandomAnthroSubject = (
  { antroEffect, anthroPrevalence, antroPercision }
) =>
  antroEffect * 1.96 ** 2 * (anthroPrevalence / 100) *
    (1 - (anthroPrevalence / 100)) / (antroPercision / 100) ** 2;

export const calculateRandomAnthroSubjectCorrection = (size) => {
  const { correctionSize, antroUnderFive } = size;
  const childrenSize = correctionSize * antroUnderFive / 100 * 0.9;
  const subjects = calculateRandomAnthroSubject(size);
  return subjects / (1 + (subjects - 1) / childrenSize);
};

export const calculateClusterAnthroSubject = (
  { antroEffect, anthroPrevalence, antroPercision }
) => antroEffect * 2.045 ** 2 * (anthroPrevalence / 100) *
    (1 - (anthroPrevalence / 100)) / (antroPercision / 100) ** 2;

export const calculateClusterAnthroSubjectCorrection = (size) => {
  const { correctionSize, antroUnderFive } = size;
  const childrenSize = correctionSize * antroUnderFive / 100 * 0.9;
  const subjects = calculateClusterAnthroSubject(size);
  return subjects / (1 + (subjects - 1) / childrenSize);
};

export const calculateClusterAnthroHouseholdCorrection = (size, sampling) => {
  const { includedAgeGroup, anthroHouseholdSize, antroUnderFive, antroNonresponse } = size;
  const subjects = calculateFormula(
    calculateClusterAnthroSubjectCorrection,
    calculateClusterAnthroSubject,
    calculateRandomAnthroSubjectCorrection,
    calculateRandomAnthroSubject,
    size,
    sampling
  );

  if (includedAgeGroup === '6-59') {
    return subjects / (anthroHouseholdSize * (antroUnderFive / 100) *
      0.9 * ((100 - antroNonresponse) / 100));
  }

  return subjects /
    (anthroHouseholdSize * (antroUnderFive / 100) * ((100 - antroNonresponse) / 100));
};

export const calculateClusterAnthroHousehold = (size, sampling) => {
  const {
    includedAgeGroup, anthroHouseholdSize, antroUnderFive, antroNonresponse
  } = size;
  const subjects = calculateFormula(
    calculateClusterAnthroSubjectCorrection,
    calculateClusterAnthroSubject,
    calculateRandomAnthroSubjectCorrection,
    calculateRandomAnthroSubject,
    size,
    sampling
  );
  if (includedAgeGroup === '6-59') {
    return subjects /
      (anthroHouseholdSize * (antroUnderFive / 100) * 0.9 * ((100 - antroNonresponse) / 100));
  }

  return subjects /
    (anthroHouseholdSize * (antroUnderFive / 100) * ((100 - antroNonresponse) / 100));
};

export const calculateRandomAnthroHouseholdCorrection = (size) => {
  const { includedAgeGroup, anthroHouseholdSize, antroUnderFive, antroNonresponse } = size;
  const subjects = calculateRandomAnthroSubjectCorrection(size);

  if (includedAgeGroup === '6-59') {
    return subjects /
      (anthroHouseholdSize * (antroUnderFive / 100) * 0.9 * ((100 - antroNonresponse) / 100));
  }
  return subjects /
    (anthroHouseholdSize * (antroUnderFive / 100) * ((100 - antroNonresponse) / 100));
};

export const calculateRandomAnthroHousehold = (size, sampling) => {
  const {
    includedAgeGroup, anthroHouseholdSize, antroUnderFive, antroNonresponse
  } = size;
  const subjects = calculateFormula(
    calculateClusterAnthroSubjectCorrection,
    calculateClusterAnthroSubject,
    calculateRandomAnthroSubjectCorrection,
    calculateRandomAnthroSubject,
    size,
    sampling
  );
  if (includedAgeGroup === '6-59') {
    return subjects /
      (anthroHouseholdSize * (antroUnderFive / 100) * 0.9 * ((100 - antroNonresponse) / 100));
  }

  return subjects /
    (anthroHouseholdSize * (antroUnderFive / 100) * ((100 - antroNonresponse) / 100));
};

export const calculateClusterMortalityPopulation = (size) => {
  const { mortalityEffect, mortalityRate, mortalityPrecision, mortalityRecall } = size;
  return mortalityEffect * 2.045 ** 2 * mortalityRate * 10000 /
    (mortalityPrecision ** 2 * mortalityRecall);
};

export const calculateClusterMortalityPopulationCorrection = (size) => {
  const { correctionSize } = size;
  const population = calculateClusterMortalityPopulation(size);
  return population / (1 + (population - 1) / correctionSize);
};

export const calculateRandomMortalityPopulation = (size) => {
  const { mortalityEffect, mortalityRate, mortalityPrecision, mortalityRecall } = size;
  return mortalityEffect * 1.96 ** 2 * mortalityRate * 10000 /
    (mortalityPrecision ** 2 * mortalityRecall);
};

export const calculateRandomMortalityPopulationCorrection = (size) => {
  const { correctionSize } = size;
  const population = calculateRandomMortalityPopulation(size);
  return population / (1 + (population - 1) / correctionSize);
};

export const calculateClusterMortalityHousehold = (size, sampling) => {
  const { mortalityHouseholdSize, mortalityNonresponse } = size;
  const mortality = calculateFormula(
    calculateClusterMortalityPopulationCorrection,
    calculateClusterMortalityPopulation,
    calculateRandomMortalityPopulationCorrection,
    calculateRandomMortalityPopulation,
    size,
    sampling
  );
  return mortality / mortalityHouseholdSize /
    ((100 - mortalityNonresponse) / 100);
};

export const calculateClusterMortalityHouseholdCorrection = (size) => {
  const { mortalityHouseholdSize, mortalityNonresponse } = size;
  const population = calculateClusterMortalityPopulationCorrection(size);
  return population / mortalityHouseholdSize / ((100 - mortalityNonresponse) / 100);
};

export const calculateRandomMortalityHousehold = (size) => {
  const { mortalityHouseholdSize, mortalityNonresponse } = size;
  const population = calculateRandomMortalityPopulation(size);
  return population / mortalityHouseholdSize / ((100 - mortalityNonresponse) / 100);
};

export const calculateRandomMortalityHouseholdCorrection = (size) => {
  const { mortalityHouseholdSize, mortalityNonresponse } = size;
  const population = calculateRandomMortalityPopulationCorrection(size);
  return population / mortalityHouseholdSize / ((100 - mortalityNonresponse) / 100);
};
