<template>
  <span v-if="!isExhaustive" data-cy="sampling-size">
    <a-alert
      v-if="isRapidSmart"
      :message="$t('components.notifications.important')"
      type="info"
      show-icon>
      <div slot="description">
        <span class="mb-20">{{ $t('components.notifications.includeClusters', { clusters: '25' }) }}</span>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="$t('components.notifications.belowFifteen')">
        </div>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="$t('components.notifications.aboveFifteen')">
        </div>
      </div>
    </a-alert>
    <h4 v-if="!isRapidSmart" class="mt-40 mb-22 txt-bold txt-font-din-medium">{{ $t('components.titles.sampleSize').toUpperCase() }}</h4>
    <a-card
      v-if="!isRapidSmart"
      :bordered="true"
      class="mb-22">
      <a-form layout="vertical">
        <a-form-item>
          <span slot="label" class="flex align-center">
            {{ $t('components.labels.correctionSmallPopulationSize') }}&nbsp;&nbsp;
            <a-tooltip
              :title="$t('components.toolTips.correctionSmallPopulationSize')"
              class="ml-8">
              <a-icon type="info-circle" style="color: #c6c7c6; font-size: 20px"/>
            </a-tooltip>
          </span>
          <a-switch v-model="size.correction" :disabled="isSupervisor" @change="setDirty"/>
        </a-form-item>
        <a-form-item>
          <a-input-number v-model="size.correctionSize" :disabled="isSupervisor" size="large" :min="1"
            pattern="\d*" class="mr-8 w101" @change="autoCorrection"/>
          {{ $t('components.labels.targetPopulationSize') }}
        </a-form-item>
        <div>
          <div class="txt-18 txt-font-din-medium txt-bold txt-black">
          {{ $t('components.labels.sampleSizeCalculationAnthropometry') }}&nbsp;&nbsp;
            <a-tooltip
              :title="$t('components.toolTips.sampleSizeCalculationAnthropometry')"
              class="ml-8">
              <a-icon type="info-circle" style="color: #c6c7c6; font-size: 20px"/>
            </a-tooltip>
            <div class="txt-16 txt-font-din-regular txt-grey mt-20">
              {{ $t('components.description.crossSectionalAnthropometricSurvey') }}
            </div>
          </div>
          <a-form-item class="mt-20">
            <span slot="label" class="flex align-center">
              {{ $t('components.labels.includedAgeGroups') }}
              <a-tooltip
                :title="$t('components.toolTips.includedAgeGroups')"
                class="ml-8">
                <a-icon type="info-circle" style="color: #c6c7c6; font-size: 20px"/>
              </a-tooltip>
            </span>
            <a-radio-group v-model="size.includedAgeGroup"
              :disabled="isSupervisor"
              @change="setDirty">
              <a-radio value="0-59" class="txt-font-din-regular">
              {{ $t('components.description.zeroFiveNineMonthsOrTargetPopulation') }}
              </a-radio>
              <a-radio value="6-59" class="txt-font-din-regular">
              {{ $t('components.description.sixFiveNineMonths') }}
              </a-radio>
            </a-radio-group>
          </a-form-item>
          <a-row class="mb-20">
            <a-col :lg="8" :md="24" class="md-margin-bottom">
              <a-input-number v-model="size.anthroPrevalence" :disabled="isSupervisor" pattern="\d*"
                :min="0" size="large" :step="0.01" class="mr-8 w70"/>
              {{ $t('components.description.estimatedPrevalence') }}
            </a-col>
            <a-col :lg="10" :md="24">
              <a-input-number v-model="size.anthroHouseholdSize" :disabled="isSupervisor" pattern="\d*"
                :min="0" size="large" :step="0.01" class="mr-8 w70"/>
              {{ $t('components.description.averageHouseholdSize') }}
              <a-tooltip
                :title="$t('components.toolTips.averageHouseholdSize')"
                class="ml-4">
                <a-icon type="info-circle" style="color: #c6c7c6; font-size: 18px"/>
              </a-tooltip>
            </a-col>
          </a-row>
          <a-row class="mb-20">
            <a-col :lg="8" :md="24" class="md-margin-bottom">
              <a-input-number v-model="size.antroPercision" :disabled="isSupervisor" pattern="\d*"
                :min="0" size="large" :step="0.01" class="mr-8 w70"/>
              {{ $t('components.description.desiredPrecision') }}
              <a-tooltip class="ml-4">
                <div slot="title">
                  {{ $t('components.toolTips.desiredPrecision') }}
                  <br><br>
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <span v-html="$t('components.toolTips.expectedPrevalence')"></span>
                </div>
                <a-icon type="info-circle" style="color: #c6c7c6; font-size: 18px"/>
              </a-tooltip>
            </a-col>
            <a-col :lg="12" :md="24">
              <a-input-number v-model="size.antroUnderFive" :disabled="isSupervisor" pattern="\d*"
                :min="0" size="large" :step="0.01" class="mr-8 w70"/>
                  {{ $t('components.description.percentageOfChildrenUnderFive') }}
              <a-tooltip
                :title="$t('components.toolTips.overEstimateChildrenUnderFive')"
                class="ml-4">
                <a-icon type="info-circle" style="color: #c6c7c6; font-size: 18px"/>
              </a-tooltip>
            </a-col>
          </a-row>
          <a-row class="mb-20">
            <a-col :lg="8" :md="24" class="md-margin-bottom">
              <a-input-number v-model="size.antroEffect" pattern="\d*"
                :disabled="sampling.samplingMethod === 'Random' || isSupervisor"
                size="large" :step="0.01" :min="0" class="mr-8 w70"/>
              {{ $t('components.description.designEffect') }}
            </a-col>
            <a-col :lg="10" :md="24">
              <a-input-number v-model="size.antroNonresponse" :disabled="isSupervisor" pattern="\d*" size="large" :step="0.01"
                :min="0" class="mr-8 w70"/>
              {{ $t('components.description.nonResponseHousehold') }}
            </a-col>
          </a-row>
          <div class="sampling-results">
            <a-row>
              <a-col :lg="12" :md="24" class="md-margin-bottom">
                <span class="mr-24 txt-bold txt-20">{{ antroSubjectsToInclude.toFixed(0) }}</span>
                {{ $t('components.description.subjectsToBeIncluded') }}
              </a-col>
              <a-col :lg="10" :md="24">
                <span class="mr-24 txt-bold txt-20">{{ antroHouseholdsToInclude.toFixed(0) }}</span>
                {{ $t('components.description.householdsToBeIncluded') }}
              </a-col>
            </a-row>
          </div>
          <a-button type="link"
              class="txt-orange txt-semi-bold mt-20
                txt-font-din-medium letter-spacing flex align-center"
              @click="anthroJustification = true">
              <a-icon
                type="plus"
                class="txt-orange mr-4"
                style="font-size: 16px;"/>
              {{ $t('components.description.addAnthropometricParameterJustifications') }}
            </a-button>
            <div class="txt-18 txt-font-din-medium txt-bold txt-black mt-40">
            {{ $t('components.labels.sampleSizeCalculationMortality') }}&nbsp;&nbsp;
            <div class="txt-16 txt-font-din-regular txt-grey mt-20">
              {{ $t('components.description.deathRateSurvey') }}
            </div>
          </div>
          <a-row class="mb-20 mt-20">
            <a-col :lg="12" :md="24">
              <a-input-number v-model="size.mortalityRate" :disabled="isSupervisor" pattern="\d*"
                :min="0" size="large" :step="0.01" class="mr-8 w70"/>
              {{ $t('components.description.estimatedDeathRatePerTenKDay') }}
            </a-col>
          </a-row>
          <a-row class="mb-20">
            <a-col :lg="12" :md="24">
              <a-input-number v-model="size.mortalityPrecision" :disabled="isSupervisor" pattern="\d*"
                size="large" :step="0.01" :min="0" class="mr-8 w70"/>
              {{ $t('components.description.desiredPrecisionPerTenKDay') }}
              <a-tooltip
                :title="$t('components.toolTips.desiredPrecisionPer10KDay')"
                class="ml-4">
                <a-icon type="info-circle" style="color: #c6c7c6; font-size: 18px"/>
              </a-tooltip>
            </a-col>
          </a-row>
          <a-row class="mb-20">
            <a-col :lg="7" :md="24" class="md-margin-bottom">
              <a-input-number v-model="size.mortalityEffect" pattern="\d*"
                :disabled="sampling.samplingMethod === 'Random' || isSupervisor"
                size="large" :step="0.01" :min="0" class="mr-8 w70"/>
              {{ $t('components.description.designEffect') }}
            </a-col>
            <a-col :lg="9" :md="24">
              <a-input-number v-model="size.mortalityHouseholdSize" :disabled="isSupervisor" pattern="\d*"
                size="large" :step="0.01" :min="0" class="mr-8 w70"/>
              {{ $t('components.description.averageHouseholdSize') }}
            </a-col>
          </a-row>
          <a-row class="mb-20">
            <a-col :lg="7" :md="24" class="md-margin-bottom">
              <a-input-number v-model="size.mortalityRecall" :disabled="isSupervisor" pattern="\d*"
                size="large" :step=".01" :min="0" class="mr-8 w70"/>
              {{ $t('components.description.recallPeriodDays') }}
              <a-tooltip
                :title="$t('components.toolTips.recallPeriodInDays')"
                class="ml-4">
                <a-icon type="info-circle" style="color: #c6c7c6; font-size: 18px"/>
              </a-tooltip>
            </a-col>
            <a-col :lg="9" :md="24">
              <a-input-number v-model="size.mortalityNonresponse" :disabled="isSupervisor" pattern="\d*"
                size="large" :step="0.01" :min="0" class="mr-8 w70"/>
              {{ $t('components.description.nonResponseHousehold') }}
            </a-col>
          </a-row>
          <div class="sampling-results">
            <a-row>
              <a-col :lg="11" :md="24" class="md-margin-bottom">
                <span class="mr-24 txt-bold txt-20">
                  {{ mortalityPopulationIncluded.toFixed(0) }}
                </span>
                {{ $t('components.description.populationToBeIncluded') }}
              </a-col>
              <a-col :lg="10" :md="24">
                <span class="mr-24 txt-bold txt-20">
                  {{ mortalityHouseholdIncluded.toFixed(0) }}
                </span>
                {{ $t('components.description.householdsToBeIncluded') }}
              </a-col>
            </a-row>
          </div>
          <a-button type="link"
              class="txt-orange txt-semi-bold mt-20
                txt-font-din-medium letter-spacing flex align-center"
              @click="mortalityJustication = true">
              <a-icon
                type="plus"
                class="txt-orange mr-4"
                style="font-size: 16px;"/>
                {{ $t('components.description.addMortalityParameterJustifications') }}
            </a-button>
        </div>
      </a-form>
    </a-card>
    <Justification
      v-if="mortalityJustication"
      :active="mortalityJustication"
      :sampling="size"
      type="mortality"
      :on-exit="onExit"/>
    <Justification
      v-if="anthroJustification"
      :active="anthroJustification"
      :sampling="size"
      type="anthro"
      :on-exit="onExit"/>
  </span>
  <span v-else>
    <h4 v-if="!isRapidSmart" class="mt-40 mb-22 txt-bold txt-font-din-medium">SAMPLE SIZE</h4>
    <a-card
      v-if="!isRapidSmart"
      :bordered="true"
      class="mb-22">
      <a-form layout="vertical">
        <div>
          <a-form-item :label="$t('components.labels.includedAgeGroups')" class="mt-20">
            <a-radio-group v-model="size.includedAgeGroup"
              :disabled="isSupervisor"
              @change="setDirty">
              <a-radio value="0-59" class="txt-font-din-regular">
                {{ $t('components.description.zeroFiveNineMonthsOrTargetPopulation') }}
              </a-radio>
              <a-radio value="6-59" class="txt-font-din-regular">
                {{ $t('components.description.sixFiveNineMonths') }}
              </a-radio>
            </a-radio-group>
          </a-form-item>
        </div>
      </a-form>
    </a-card>
  </span>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import debounce from 'lodash/debounce';
import {
  calculateFormula,
  calculateClusterAnthroSubjectCorrection,
  calculateClusterAnthroSubject,
  calculateRandomAnthroSubjectCorrection,
  calculateRandomAnthroSubject,
  calculateClusterAnthroHouseholdCorrection,
  calculateClusterAnthroHousehold,
  calculateRandomAnthroHouseholdCorrection,
  calculateRandomAnthroHousehold,
  calculateClusterMortalityPopulationCorrection,
  calculateClusterMortalityPopulation,
  calculateRandomMortalityPopulationCorrection,
  calculateRandomMortalityPopulation,
  calculateClusterMortalityHouseholdCorrection,
  calculateClusterMortalityHousehold,
  calculateRandomMortalityHouseholdCorrection,
  calculateRandomMortalityHousehold
} from '../../../../util/calculations';

export default {
  name: 'MetadataSamplingSize',
  components: {
    Justification: () => import('../justification/justification')
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    sampling: {
      type: Object,
      default: () => {}
    },
    onSave: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      firstLoad: true,
      mortalityJustication: false,
      anthroJustification: false,
      size: {
        correction: true,
        correctionSize: 10001,
        includedAgeGroup: '6-59',
        anthroPrevalence: 20,
        anthroHouseholdSize: 5,
        antroPercision: 5,
        antroUnderFive: 20,
        antroEffect: 2,
        antroNonresponse: 10,

        mortalityRate: 2,
        mortalityPrecision: 1,
        mortalityEffect: 2,
        mortalityHouseholdSize: 5,
        mortalityRecall: 90,
        mortalityNonresponse: 10
      }
    };
  },
  computed: {
    ...mapGetters(['isRapidSmart', 'isExhaustive']),
    ...mapState({
      metadata: state => state.survey.metadata,
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    }),
    antroSubjectsToInclude() {
      return calculateFormula(
        calculateClusterAnthroSubjectCorrection,
        calculateClusterAnthroSubject,
        calculateRandomAnthroSubjectCorrection,
        calculateRandomAnthroSubject,
        this.size,
        this.sampling
      );
    },
    antroHouseholdsToInclude() {
      return calculateFormula(
        calculateClusterAnthroHouseholdCorrection,
        calculateClusterAnthroHousehold,
        calculateRandomAnthroHouseholdCorrection,
        calculateRandomAnthroHousehold,
        this.size,
        this.sampling
      );
    },
    mortalityPopulationIncluded() {
      return calculateFormula(
        calculateClusterMortalityPopulationCorrection,
        calculateClusterMortalityPopulation,
        calculateRandomMortalityPopulationCorrection,
        calculateRandomMortalityPopulation,
        this.size,
        this.sampling
      );
    },
    mortalityHouseholdIncluded() {
      return calculateFormula(
        calculateClusterMortalityHouseholdCorrection,
        calculateClusterMortalityHousehold,
        calculateRandomMortalityHouseholdCorrection,
        calculateRandomMortalityHousehold,
        this.size,
        this.sampling
      );
    }
  },
  watch: {
    'sampling.samplingMethod': {
      deep: true,
      handler(newValue) {
        if (newValue === 'Random') {
          this.size.mortalityEffect = 1;
          this.size.antroEffect = 1;
        } else if (newValue === 'Cluster') {
          this.size.mortalityEffect = 0;
          this.size.antroEffect = 0;
        }
      }
    },
    size: {
      deep: true,
      // eslint-disable-next-line func-names
      handler: debounce(function() {
        this.$emit('input', {
          ...this.size,
          antroSubjectsToInclude: this.antroSubjectsToInclude,
          antroHouseholdsToInclude: this.antroHouseholdsToInclude,
          mortalityPopulationIncluded: this.mortalityPopulationIncluded,
          mortalityHouseholdIncluded: this.mortalityHouseholdIncluded
        });
        if (!this.firstLoad) this.onSave();
        this.firstLoad = false;
      }, 1000)
    }
  },
  created() {
    this.setDataFromStore();
    this.$emit('input', {
      ...this.size,
      antroSubjectsToInclude: this.antroSubjectsToInclude,
      antroHouseholdsToInclude: this.antroHouseholdsToInclude,
      mortalityPopulationIncluded: this.mortalityPopulationIncluded,
      mortalityHouseholdIncluded: this.mortalityHouseholdIncluded
    });
  },
  methods: {
    ...mapActions(['setDirty']),
    setDataFromStore() {
      const {
        correction = true,
        correctionSize,
        includedAgeGroup,
        anthroPrevalence,
        anthroHouseholdSize,
        antroPercision,
        antroUnderFive,
        antroEffect,
        antroNonresponse,
        mortalityRate,
        mortalityPrecision,
        mortalityEffect,
        mortalityHouseholdSize,
        mortalityRecall,
        mortalityNonresponse
      } = (this.metadata || {});
      this.size = {
        correction,
        correctionSize: correctionSize || 10001,
        includedAgeGroup: includedAgeGroup || '6-59',
        anthroPrevalence: anthroPrevalence || 0,
        anthroHouseholdSize: anthroHouseholdSize || 0,
        antroPercision: antroPercision || 0,
        antroUnderFive: antroUnderFive || 0,
        antroEffect: antroEffect || 0,
        antroNonresponse: antroNonresponse || 0,
        mortalityRate: mortalityRate || 0,
        mortalityPrecision: mortalityPrecision || 0,
        mortalityEffect: mortalityEffect || 0,
        mortalityHouseholdSize: mortalityHouseholdSize || 0,
        mortalityRecall: mortalityRecall || 0,
        mortalityNonresponse: mortalityNonresponse || 0
      };
    },
    autoCorrection() {
      this.setDirty();
      if (!this.size.correctionSize) {
        this.size.correctionSize = 1;
      }
      if (this.size.correctionSize <= 10000) {
        this.size.correction = true;
      } else {
        this.size.correction = false;
      }
    },
    onExit() {
      this.anthroJustification = false;
      this.mortalityJustication = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.sampling-results {
  width: 640px;
  padding: 10px 20px 10px;
  border-bottom: 1px solid #cfd0cf;
  border-top: 1px solid #cfd0cf;
  background: #f4f4f4;
}

@media (max-width: 900px) {
  .sampling-results {
    width: 100%;
  }
}
</style>
